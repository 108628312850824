<template>
  <headless-form @submitted="submitForm">
    <h1 class="text-grey-dark text-[17px] md:text-2xl mb-4 md:mb-6">Send us an enquiry</h1>
    <div class="grid grid-cols-12 gap-6 md:gap-4">
      <BaseFormElementWrapper class="col-span-12 sm:col-span-6 md:col-span-12 lg:col-span-6">
        <BaseInput
          v-model="detail.firstName"
          label="First name"
          name="firstname"
          type="text"
        />
      </BaseFormElementWrapper>
      <BaseFormElementWrapper class="col-span-12 sm:col-span-6 md:col-span-12 lg:col-span-6">
        <BaseInput
          v-model="detail.lastName"
          label="Last name"
          name="lastname"
          type="text"
        />
      </BaseFormElementWrapper>
      <BaseFormElementWrapper class="col-span-12 sm:col-span-6 md:col-span-12 lg:col-span-6">
        <BaseInput
          v-model="detail.phone"
          label="Phone number"
          name="phone"
          type="text"
        />
      </BaseFormElementWrapper>
      <BaseFormElementWrapper class="col-span-12 sm:col-span-6 md:col-span-12 lg:col-span-6">
        <BaseInput
          v-model="detail.email"
          label="Email address"
          name="email"
          type="text"
          rules="required|email"
        />
      </BaseFormElementWrapper>
      <BaseFormElementWrapper class="col-span-12">
        <BaseTextArea
          v-model="detail.message"
          label="Your message"
          name="message"
          type="text"
          rules="required"
        />
      </BaseFormElementWrapper>
      <BaseFormElementWrapper class="col-span-12">
        <BaseCheckbox
          v-model="detail.consent"
          label="I consent to my data being used by Highworth Insurance and understand that I can request to have my information at any time. (GDPR)"
          rules="required"
        />
      </BaseFormElementWrapper>
      <BaseFormElementWrapper class="flex col-span-11 justify-end">
        <button type="submit" :disabled="submitButton.disabled" class="transition-standard inline-block border-2 border-orange bg-orange font-bold rounded-full whitespace-nowrap py-2 px-5 md:px-8 text-white text-base md:text-xl hover:text-orange hover:bg-transparent cursor-pointer">{{ submitButton.text }}</button>
      </BaseFormElementWrapper>
    </div>
  </headless-form>

  <Teleport to="#modals" v-if="state.showModal">
    <PopupModal class="w-[562px]" topIcon="faq-include" closeButton="Close" @close="toggleModal">
      <div class="content-text text-grey-dark font-light text-[17px] leading-loose pt-6 pb-5 px-16 text-center">
        {{ state.error }}
      </div>
    </PopupModal>
  </Teleport>
</template>

<script setup>
import { HeadlessForm } from '@mbs-chilli/npm-headless-ui-vue'
import { inject, reactive, ref } from 'vue'
import BaseCheckbox from './form-elements/BaseCheckbox'
import BaseFormElementWrapper from './form-elements/BaseFormElementWrapper.vue'
import BaseInput from './form-elements/BaseInput.vue'
import BaseTextArea from './form-elements/BaseTextArea.vue'
import PopupModal from './PopupModal.vue'

const state = reactive({
  error: "An error has occurred. Please try again later.",
  showModal: false,
})

const detail = reactive({
  firstName: null,
  lastName: null,
  phone: null,
  email: null,
  message: null,
})

const submitButton = reactive({
  text: 'Send your enquiry',
  disabled: false,
})

const submitForm = () => {
  submitButton.disabled = true
  oc.ajax('onSubmitContactForm', {
    data: {
      from: detail.email,
      to: "Highworth.contact@highworthinsurance.co.uk",
      reply: detail.email,
      email_subject: "Contact form submission from " + detail.firstName + " " + detail.lastName,
      email_text: "Contact form has been submitted.",
      form_data: detail
    },
    success: (data) => {
      //console.log(data)
      window.location = "/thank-you"
    },
    error: (data) => {
      //console.log(data)
      submitButton.disabled = false
      toggleModal()
    }
  })
}

const toggleModal = (() => {
  state.showModal = !state.showModal
  state.showModal ? $("body").addClass("model-open") : $("body").removeClass("model-open")
})

</script>