import { defineRule } from "vee-validate";

defineRule("required", (value) => {
  if (Array.isArray(value) && value.length == 0) {
    return "Please answer this question.";
  }
  if (value == null || value == "") {
    return "Please answer this question.";
  }
  return true;
});

defineRule("email", (value) => {
 if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value))
  {
    return true;
  }
  return "Please enter a valid email address.";
});