<template>
  <headless-input v-slot="{ state }" :rules="rules">
    <div class="flex gap-2 items-start">    
      <div>
        <input
          class="w-6 h-6 border border-grey-lighter"
          :class="state === 'error' ? 'border-red-600' : ''"
          type="checkbox"
          id="data-confirm"
          v-bind="$attrs"
          :checked="modelValue"
          @change="$emit('update:modelValue', $event.target.checked)"
        />
      </div>
    <BaseLabel v-if="label" class="font-light">
      {{ label }}
      <span v-if="rules === 'required'" class="text-red-600 ml-0.5">*</span>
    </BaseLabel>
    </div>
    <input-error class="text-xs text-red-600 mt-2"></input-error>
  </headless-input>
</template>


<script setup>
import { HeadlessInput, InputError } from '@mbs-chilli/npm-headless-ui-vue'
import { ref } from 'vue'
import BaseLabel from './BaseLabel.vue';

const emits = defineEmits([
  'update:modelValue'
])

const modelValue = ref(null)

const props = defineProps({
  label: {
    type: String,
    default: ''
  },
  rules: {
    type: String,
    default: null
  },
})
</script>