<template>
  <headless-input v-slot="{ state }" :rules="rules">
    <BaseLabel v-if="label">
      {{ label }}
      <span v-if="rules === 'required'" class="text-red-600 ml-0.5">*</span>
    </BaseLabel>
    <base-select-field
      class="field w-full border border-grey-lighter bg-white text-grey-light text-base h-10 px-2 md:h-12 md:px-3 focus:outline-none focus:ring-0"
      :class="state === 'error' ? 'border-red-600' : ''"
      v-model="modelValue"
      :elements="elements"
      :placeholder="placeholder"
      :modelValue="modelValue"
      @change="onChange($event)"
    ></base-select-field>
    <input-error class="text-xs text-red-600 mt-2"></input-error>
  </headless-input>
</template>


<script setup>
import { ref } from 'vue'
import { HeadlessInput, InputError } from '@mbs-chilli/npm-headless-ui-vue'
import BaseLabel from './BaseLabel.vue'
import BaseSelectField from './BaseSelectField.vue'

const emits = defineEmits([
  'update:modelValue'
])

const modelValue = ref(null)

const props = defineProps({
  label: {
    type: String,
    default: ''
  },
  placeholder: {
    type: String,
    default: ''
  },
  elements: {
    type: Array,
    required: true
  },
  rules: {
    type: String,
    default: null
  },
})

const onChange = ((event) => {
  emits('update:modelValue', event.target.value)
  $(event.target).removeClass("text-grey-light")
  $(event.target).addClass("text-blue-dark")
})
</script>