<template>
<div v-if="elements" class="faq-accordion mt-3 md:mt-1 mb-5 text-left">
  <ul class="grid grid-cols-1 md:grid-cols-4 gap-x-5 gap-y-0 md:gap-y-12">
    <li v-for="element in elements" class="transition-standard col-span-1 md:hover:-mt-2 md:hover:mb-2 md:hover:shadow-hw">
      <div @click="clickAction(element, $event)" class="title relative h-auto md:h-full px-5 xs:px-16 md:px-6 py-5 md:pt-11 md:pb-7 border-b md:border-none bg-blue-grey_lightest bg-opacity-50 border-white cursor-pointer md:before:block before:absolute before:top-0 before:-translate-y-1/2 before:left-1/2 before:-translate-x-1/2 before:hidden before:w-14 before:h-14 before:rounded-full before:bg-blue-grey_lighter after:bg-faq-include after:bg-center after:bg-no-repeat after:bg-[length:27px_24px] md:after:bg-[length:35px_31px] after:absolute after:top-6 md:after:top-0 md:after:-translate-y-1/2 after:left-5 md:after:left-1/2 md:after:-translate-x-1/2 after:hidden xs:after:block after:w-[27px] md:after:w-[35px] after:h-[24px] md:after:h-[31px]">
        <h3 class="text-blue-dark text-[17px] md:text-lg">{{ element.question }}</h3>
        <div class="opener hidden xs:block md:hidden transition-standard bg-blue-grey_light rounded-full w-6 h-6 absolute top-6 md:top-7 right-7">
          <div class="absolute top-1/2 -translate-y-1/2 left-0 right-0 m-auto w-0 h-0 border-solid border-t-4 border-t-blue-dark border-r-4 border-r-transparent border-b-0 border-b-transparent border-l-4 border-l-transparent"></div>
        </div>
      </div>
      <div class="content text-[15px] md:text-[17px] leading-loose px-5 md:px-8 py-6 md:py-9" style="display: none;">
        <div v-html="element.answer" class="content-text font-light text-grey-dark md:basis-1/3"></div>
      </div>
    </li>
  </ul>
</div>
<Teleport to="#modals" v-if="state.showModal">
  <PopupModal class="w-[562px]" topIcon="faq-include" closeButton="Close FAQ" @close="toggleModal">
    <div class="absolute left-1/2 top-0 -translate-x-1/2 -translate-y-1/2 rounded-full bg-blue-grey_lighter w-16 h-16 bg-faq-include bg-center bg-no-repeat bg-[length:35px_31px]"></div>
    <h1 class="bg-blue-grey_lightest text-blue-dark pt-12 pb-7 px-14 text-[23px] rounded-t-md text-center">{{ state.question }}</h1>
    <div class="content-text text-grey-dark font-light text-[17px] leading-loose pt-6 pb-5 px-16 text-center" v-html="state.answer"></div>
  </PopupModal>
</Teleport>
</template>

<script setup>
import { computed, nextTick, onMounted, onBeforeUnmount, reactive, ref } from 'vue'
import PopupModal from './PopupModal.vue'

const state = reactive({
  windowWidth: window.innerWidth,
  question: "",
  answer: "",
  showModal: false,
})

const props = defineProps({
  jsonelements: {
    type: String,
    default: null
  },
})

const elements = computed(() => {
  return JSON.parse(props.jsonelements)
})

onMounted(() => {
  nextTick(() => {
    window.addEventListener('resize', onResize)
  })
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', onResize)
})

const onResize = (() => {
  state.windowWidth = window.innerWidth
})

const clickAction = ((data, event) => {
  if(state.windowWidth >= 768) {
    updateModal(data)
  } else {
    toggleAccordion(event.target)
  }
})

const toggleAccordion = ((elem) => {
  if(!$(elem).hasClass("title")) {
    elem = $(elem).closest(".title")
  }
  $(elem).closest("li").toggleClass("bg-white shadow-hw-subtle").siblings().removeClass("bg-white shadow-hw-subtle").find(".content").slideUp()
  $(elem).closest("li").siblings().find(".opener").removeClass("rotate-180")
  $(elem).next(".content").slideToggle()
  $(elem).find(".opener").toggleClass("rotate-180")
})

const updateModal = ((data) => {
  state.question = data.question
  state.answer = data.answer
  toggleModal()
})

const toggleModal = (() => {
  state.showModal = !state.showModal
  state.showModal ? $("body").addClass("model-open") : $("body").removeClass("model-open")
})
</script>