<template>

  <headless-form @submitted="submitForm" class="grid grid-cols-12 gap-3 md:gap-5">

    <div class="col-span-12 xl:col-span-2 xl:row-span-5 text-center xl:text-left">
      <h2 class="text-blue-dark text-[22px] md:text-[25px]">Get my Quote</h2>
      <p class="text-blue-dark text-[15px] font-light leading-relaxed md:leading-loose">Please fill in this form so we can quote you accurately</p>
    </div>

    <template v-if="isMasterForm || isCategory">
      <BaseFormElementWrapper class="col-span-12 md:col-span-7 xl:col-span-6">
        <BaseSelect
          :elements="productsList"
          v-model="detail.product"
          placeholder="I'd like a quote for..."
          rules="required"
        />
      </BaseFormElementWrapper>
      <div class="hidden md:block md:col-span-5 xl:col-span-4"></div>
    </template>
    <BaseFormElementWrapper class="col-span-12 md:col-span-2">
      <BaseSelect
        :elements="titles"
        v-model="detail.title"
        placeholder="Title..."
        rules="required"
      />
    </BaseFormElementWrapper>
    <BaseFormElementWrapper class="col-span-12 md:col-span-5 xl:col-span-4">
      <BaseInput
        v-model="detail.firstName"
        placeholder="First name"
        type="text"
        rules="required"
      />
    </BaseFormElementWrapper>
    <BaseFormElementWrapper class="col-span-12 md:col-span-5 xl:col-span-4">
      <BaseInput
        v-model="detail.lastName"
        placeholder="Last name"
        type="text"
        rules="required"
      />
    </BaseFormElementWrapper>
    <BaseFormElementWrapper class="col-span-12 md:col-span-2">
      <BaseInput
        v-model="detail.postcode"
        placeholder="Postcode"
        type="text"
      />
    </BaseFormElementWrapper>
    <BaseFormElementWrapper class="col-span-12" :class="colSpan">
      <BaseInput
        v-model="detail.phone"
        placeholder="Phone number"
        type="text"
        rules="required"
      />
    </BaseFormElementWrapper>
    <BaseFormElementWrapper class="col-span-12" :class="colSpan">
      <BaseInput
        v-model="detail.email"
        placeholder="Email address"
        type="text"
        rules="required|email"
      />
    </BaseFormElementWrapper>
    <template v-if="!isMasterForm">
      <BaseFormElementWrapper v-if="!isPropertyInsurance" class="col-span-12 md:col-span-2">
        <BaseInput
          v-model="detail.value"
          placeholder="Value (approx)"
          type="text"
        />
      </BaseFormElementWrapper>
      <template v-if="isPropertyInsurance">
        <BaseFormElementWrapper class="col-span-12 md:col-span-2">
          <BaseSelect
            :elements="propertyTypes"
            v-model="detail.propertyType"
            placeholder="Property type..."
          />
        </BaseFormElementWrapper>
        <BaseFormElementWrapper class="col-span-12 md:col-span-5 xl:col-span-4">
          <BaseInput
            v-model="detail.numberOfBedrooms"
            placeholder="Number of bedrooms"
            type="text"
          />
        </BaseFormElementWrapper>
        <BaseFormElementWrapper class="col-span-12 md:col-span-5 xl:col-span-4">
          <BaseSelect
            :elements="coverTypes"
            v-model="detail.coverType"
            placeholder="Type of cover required..."
          />
        </BaseFormElementWrapper>
      </template>
      <BaseFormElementWrapper v-if="!isPropertyInsurance" class="col-span-12 xl:col-span-10">
        <BaseInput
          v-model="detail.description"
          placeholder="Description"
          type="text"
        />
      </BaseFormElementWrapper>
    </template>
    <div class="col-span-12 xl:col-span-10 flex flex-col-reverse text-center md:text-left md:flex-row items-center justify-between gap-4 md:gap-8">

      <span class="text-[15px] md:text-[19px] text-orange relative md:pl-6 md:before:inline-block md:before:align-top md:before:w-[15px] md:before:h-[19px] md:before:absolute md:before:top-1.5 md:before:left-0 md:before:bg-icon-phone-orange md:before:bg-center md:before:bg-no-repeat md:before:bg-[length:15px_19px]">If you would prefer, feel free to call an account manager on <span v-html="phonelink"></span></span>

      <button type="submit" :disabled="submitButton.disabled" class="transition-standard inline-block border-2 border-orange bg-orange font-bold rounded-full whitespace-nowrap py-2 px-5 md:px-8 text-white text-base md:text-xl hover:text-orange hover:bg-transparent cursor-pointer">{{ submitButton.text }}</button>

    </div>
  </headless-form>

  <Teleport to="#modals" v-if="state.showModal">
    <PopupModal class="w-[562px]" topIcon="faq-include" closeButton="Close" @close="toggleModal">
      <div class="content-text text-grey-dark font-light text-[17px] leading-loose pt-6 pb-5 px-16 text-center">
        {{ state.error }}
      </div>
    </PopupModal>
  </Teleport>

</template>


<script setup>
  import {computed, onMounted, reactive, ref} from 'vue'
  import { HeadlessForm } from '@mbs-chilli/npm-headless-ui-vue'
  import BaseFormElementWrapper from './form-elements/BaseFormElementWrapper.vue'
  import BaseInput from './form-elements/BaseInput.vue'
  import BaseSelect from './form-elements/BaseSelect.vue'
  import PopupModal from './PopupModal.vue'
  import {titles, propertyTypes, coverTypes} from "../lists";

  const state = reactive({
    error: "An error has occurred. Please try again later.",
    showModal: false,
  })

  const props = defineProps({
    phonelink: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    code: {
      type: String,
      default: null,
    },
    category: {
      type: String,
      default: null,
    },
    product: {
      type: String,
      default: null,
    },
    products: {
      type: String,
      default: null,
    },
    introducerCode: {
      type: String,
      default: null,
    }
  })

  onMounted(() => {
    if(props.code)
    {
      detail.product = props.code
    }
    if(props.introducerCode)
    {
      detail.introducerCode = props.introducerCode
    }
  })

  function isEmpty(value)
  {
    return (value == null || value.length === 0)
  }

  const isMasterForm = computed(() => {
    return !isEmpty(props.products) && isEmpty(props.category)
  })

  const isPropertyInsurance = computed(() => {
    return props.type === "property"
  })

  const isCategory = computed(() => {
    return !isEmpty(props.category) && !isEmpty(props.products)
  })

  const productsList = computed(() => {
    return JSON.parse(props.products)
  })

  const colSpan = computed(() => ({
    "md:col-span-5 xl:col-span-4": isPropertyInsurance.value || isMasterForm.value,
    "md:col-span-4 xl:col-span-3": !isPropertyInsurance.value
  }))

  const detail = reactive({
    title: null,
    firstName: null,
    lastName: null,
    postcode: null,
    phone: null,
    email: null,
    propertyType: null,
    numberOfBedrooms: null,
    coverType: null,
    product: null
  })

  const submitForm = () => {
    submitButton.disabled = true
    oc.ajax('onSubmitLeadForm', {
      data: detail,
      success: (data) => {
        //console.log(data)
        window.location = "/thank-you"
      },
      error: (data) => {
        //console.log(data)
        submitButton.disabled = false
        toggleModal()
      }
    })
  }

  const submitButton = reactive({
    text: 'Get my quote »',
    disabled: false,
  })

  const toggleModal = (() => {
    state.showModal = !state.showModal
    state.showModal ? $("body").addClass("model-open") : $("body").removeClass("model-open")
  })
</script>