export const titles = [
    {
        option: {
            name: "Mr",
            value: "Mr",
        }
    },
    {
        option: {
            name: "Mrs",
            value: "Mrs",
        }
    },
    {
        option: {
            name: "Miss",
            value: "Miss",
        }
    },
    {
        option: {
            name: "Ms",
            value: "Ms",
        }
    },
    {
        option: {
            name: "Dr",
            value: "Dr",
        }
    },
    {
        option: {
            name: "Sir",
            value: "Sir",
        }
    },
    {
        option: {
            name: "Lady",
            value: "Lady",
        }
    },
    {
        option: {
            name: "Reverend",
            value: "Reverend",
        }
    }
];

export const propertyTypes = [
    {
        option: {
            name: "House-detached",
            value: "House-detached",
        }
    },
    {
        option: {
            name: "House-semi",
            value: "House-semi",
        }
    },
    {
        option: {
            name: "House-terraced",
            value: "House-terraced",
        }
    },
    {
        option: {
            name: "Flat",
            value: "Flat",
        }
    },
    {
        option: {
            name: "Bungalow",
            value: "Bungalow",
        }
    },
    {
        option: {
            name: "Chalet",
            value: "Chalet",
        }
    },
    {
        option: {
            name: "Other",
            value: "Other",
        }
    }
]

export const coverTypes = [
    {
        option: {
            name: "Buildings and contents",
            value: "Buildings and contents",
        }
    },
    {
        option: {
            name: "Buildings only",
            value: "Buildings only",
        }
    },
    {
        option: {
            name: "Contents only",
            value: "Contents only",
        }
    }
]