<template>
  <div @click.self="closeModal" class="z-[999999999] left-0 top-0 fixed flex items-center justify-center bg-blue-dark bg-opacity-30 w-full h-full cursor-pointer">
    <div v-bind="$attrs" class="relative mx-4 rounded-md bg-white shadow-hw cursor-auto">
      <slot></slot>
      <div v-if="closeButton" class="flex w-full pt-4 pb-8 items-center justify-center">
        <a class="py-1.5 px-6 text-xs text-blue-dark bg-blue-grey_light border-2 border-blue-grey_light rounded-full cursor-pointer hover:bg-white transition-standard" @click="closeModal">{{ closeButton }}</a>
      </div>
    </div>
  </div>
</template>

<script setup>
defineOptions({
  inheritAttrs: false
})

const props = defineProps([
  'closeButton'
])

const emits = defineEmits([
  'close'
])

const closeModal = (() => {
  emits('close')
})
</script>

<style>
body.model-open {
  overflow: hidden;
}
</style>