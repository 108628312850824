import { createApp } from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import './rules'

const app = createApp()

app.use(VueAxios, axios)
app.provide('axios', app.config.globalProperties.axios)  // provide 'axios'
app.config.compilerOptions.whitespace = 'preserve'
app.component('FrequentlyAskedQuestions', require('./components/FrequentlyAskedQuestions.vue').default)
app.component('GetMyQuote', require('./components/GetMyQuote.vue').default)
app.component('ContactUs', require('./components/ContactUs.vue').default)
app.mount('#app')





// Plugins
import $ from 'jquery'
import 'slick-carousel'
window.jQuery = window.$ = $;


// Passive listeners
jQuery.event.special.touchstart = {
  setup: function( _, ns, handle ) {
      this.addEventListener("touchstart", handle, { passive: !ns.includes("noPreventDefault") });
  }
};
jQuery.event.special.touchmove = {
  setup: function( _, ns, handle ) {
      this.addEventListener("touchmove", handle, { passive: !ns.includes("noPreventDefault") });
  }
};
jQuery.event.special.wheel = {
  setup: function( _, ns, handle ){
      this.addEventListener("wheel", handle, { passive: true });
  }
};
jQuery.event.special.mousewheel = {
  setup: function( _, ns, handle ){
      this.addEventListener("mousewheel", handle, { passive: true });
  }
};


// Breakpoints
const bp_xs = 420;
const bp_sm = 768;
const bp_md = 1024;
const bp_lg = 1140;
const bp_xl = 1240;


// Sticky main header
window.onscroll = () => toggleMainNav();
var layoutHeader = document.getElementById("main-header");
var sticky = layoutHeader.offsetTop + layoutHeader.scrollHeight / 3 * 2;

function toggleMainNav() {
  if(window.innerWidth >= bp_md) {
    if (window.scrollY > sticky) {
      layoutHeader.classList.add("header-fixed");
    } else {
      layoutHeader.classList.remove("header-fixed");
    }
  }
}


// Main menu hamburger
$(".main-menu-hamburger").on("click", function() {
  $(this).toggleClass("open");
  $("nav#main-nav").slideToggle();
});


// Main menu toggle submenu items
$(".main-menu-has-children").on("click", function(e) {
  if(window.innerWidth < bp_md) {
    e.stopPropagation();
    e.stopImmediatePropagation();
    $(this).next("ul").slideToggle();
  }
});


// Slick slider for 'image-slider-cta-info-box'
$(".img-slider").slick({
  infinite: !0,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: !0,
  autoplaySpeed: 8e3,
  dots: !0,
  arrows: !1
});


// Slick slider for 'testimonials-slider'
$(".testimonials-slider").slick({
  infinite: !0,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: !0,
  autoplaySpeed: 8e3,
  dots: !1,
  arrows: !0
});


// Accordions
$(".accordion .title").on("click", function() {
  $(this).closest("li").toggleClass("bg-white shadow-hw-subtle").siblings().removeClass("bg-white shadow-hw-subtle").find(".content").slideUp();
  $(this).closest("li").siblings().find(".opener").removeClass("rotate-180");
  $(this).next(".content").slideToggle();
  $(this).find(".opener").toggleClass("rotate-180");
});


// Drop downs
$(".hw-dropdown").on("click", function() {
  $(this).find(".dropdown-arrow").toggleClass("rotate-180");
  $(this).next(".hw-dropdown-list").slideToggle();
  $(".hw-dropdown").not(this).find(".dropdown-arrow").removeClass("rotate-180");
  $(".hw-dropdown-list").not($(this).next(".hw-dropdown-list")).slideUp();
});