<template>
  <headless-input v-slot="{ state }" :rules="rules" class="flex flex-col gap-2">
    <BaseLabel v-if="label">
      {{ label }}
      <span v-if="rules === 'required'" class="text-red-600 ml-0.5">*</span>
    </BaseLabel>
    <input
      class="w-full border border-grey-lighter bg-white text-base text-blue-dark h-10 px-2 md:h-12 md:px-3 focus:outline-none focus:ring-0"
      :class="state === 'error' ? 'border-red-600' : ''"
      v-model="modelValue"
      v-bind="$attrs"
      @input="$emit('update:modelValue', $event.target.value)"
    />
    <input-error class="text-xs text-red-600"></input-error>
  </headless-input>
</template>


<script setup>
import { HeadlessInput, InputError } from '@mbs-chilli/npm-headless-ui-vue'
import { ref } from 'vue'
import BaseLabel from './BaseLabel.vue'

const emits = defineEmits([
  'update:modelValue'
])

const modelValue = ref(null)

const props = defineProps({
  label: {
    type: String,
    default: ''
  },
  rules: {
    type: String,
    default: null
  },
})
</script>